<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>

      <b-card-title class="font-weight-bolder">
        {{ title }}
      </b-card-title>

      <div class="tab-header" v-if="true">
        <div class="date-range-wrapper">
          <b-form-group>
            <flat-pickr
              v-model="date_range_str"
              ref="dateRangePickr"
              class="form-control date-range-picker"
              placeholder="請選擇日期範圍"
              :disabled="false"
              :config="{
              mode: 'range',
              maxDate: new Date(),
            }"
              @on-change="onDateRangeChange"
            />
          </b-form-group>
          <b-button-group>
            <b-button
              :variant="date_range_str === date_range_list.d7 ? 'outline-primary' : 'outline-secondary'"
              :pressed="date_range_str === date_range_list.d7"
              @click="() => date_range_str = date_range_list.d7"
            >近7天
            </b-button>
            <b-button
              :variant="date_range_str === date_range_list.d30 ? 'outline-primary' : 'outline-secondary'"
              :pressed="date_range_str === date_range_list.d30"
              @click="() => date_range_str = date_range_list.d30"
            >近30天
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="() => date_range_str = ''"
            >
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </b-button-group>
        </div>
      </div>

      <table-header
        ref="header"
        :title="title"
        :hide-title="true"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :search-tips="$t('kiosk.no_nursing_record_search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      >
      </table-content>
    </template>
  </page-content>
</template>

<script>
import common from "@/common";
import PageContent from "@/components/PageContent.vue";
import TableSearch from "@/components/TableSearch.vue";
import TableHeader from "@/components/TableHeader.vue";
import TableContent from "@/components/TableContent.vue";
import SlotFloor from "@/views/bed/SlotFloor.vue";
import _ from "lodash";
import flatPickr from 'vue-flatpickr-component';

export default {
  name: "kioskNoNursingRecord",
  components: {
    flatPickr,
    SlotFloor, PageContent,
    TableContent, TableHeader, TableSearch
  },
  data() {
    return {
      // Table
      title: common.getMenuName('kioskNoNursingRecord'),
      view_permission: common.checkPermission('Kiosk_Menu_NursingWorkLog'),
      header_actions: [],
      table_actions: [],
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },

      status_map: [], // 會員狀態
      bg_period_map: [], // 血糖類型
      dietary_map: [], // 飲食狀況
      conscious_map: [], // 意識
      emotional_map: [], // 情緒表現

      taskType: '',
      taskTypeDesc: '',
      date_range_str: [this.$moment().subtract(7 - 1, 'day').format('YYYY-MM-DD'), this.$moment()
        .format('YYYY-MM-DD')].join(' to '), // a string, for v-model
      date_range_list: {
        d7: [this.$moment().subtract(7 - 1, 'day').format('YYYY-MM-DD'), this.$moment()
          .format('YYYY-MM-DD')].join(' to '),
        d30: [this.$moment().subtract(30 - 1, 'day').format('YYYY-MM-DD'), this.$moment()
          .format('YYYY-MM-DD')].join(' to '),
      },

      // Filter
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: [],
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: []
        },
        // {
        //   field: 'memberCode',
        //   label: 'common.member',
        //   type: 'select',
        //   options: [],
        //   searchable: true
        // },
        // {
        //   field: 'statusList',
        //   label: 'member.status',
        //   type: 'select',
        //   multiple: true,
        //   options: []
        // },
        {
          field: 'taskType',
          label: 'kiosk.taskName',
          type: 'select',
          options: [],
          clearable: false,
        },
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: common.getCustomConfigByKey('floorID'),
        roomID: common.getCustomConfigByKey('roomID'),
        memberCode: common.getCustomConfigByKey('memberCode'),
        taskType: this.$route.query.taskType ?? null,
      },

      // Modal
      editingLog: null,
    }
  },

  computed: {
    date_range() {
      const range = this.date_range_str.split(' to ')
      range[0] = range[0] ?? null
      range[1] = range[1] ?? range[0] ?? null
      return range
    },
    keyOfCurrentTaskTypeMeasureTime() {
      const measureTimeKeyMap = {
        "bp": "bP_Measured_DateTime",
        "bo": "spO2_Measured_DateTime",
        "glucose": "bG_Measured_DateTime",
        "bt": "bT_Measured_DateTime",
        "breath": "breaths_Measured_DateTime",
        "conscious": "conscious_Measured_DateTime",
        "dietary": "dietary_Measured_DateTime",
        "emotional": "emotional_Measured_DateTime",
        "handgrip": "handgrip_Measured_DateTime",
        "height": "height_Measured_DateTime",
        "weight": "weight_Measured_DateTime",
      }
      return measureTimeKeyMap[this.taskType]
    },
    columns() {
      return [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: common.getI18n('kiosk.last_measure_time') + (this.taskTypeDesc ? ` (${this.taskTypeDesc})` : ''),
          field: '_measureTime',
          width: '190px',
          type: 'number',
          // change: this.changeTimeFormat,
          changeHTML: this.getMeasureDetail,
        },
        {
          label: 'member.chineseName',
          field: 'chineseName',
          width: '200px',
          changeHTML: function (value, row) {
            let avatar
            if (row.photo) {
              avatar = common.getServerUrl() + row.photo
            } else {
              avatar = require('@/assets/images/user.png')
            }
            return `
              <div class="d-flex align-items-center">
                <div class="b-avatar badge-secondary rounded-circle default-photo-bg mr-05">
                  <span class="b-avatar-img">
                    <img src="${avatar}" alt="">
                  </span>
                </div>
                <div>${value}</div>
              </div>
            `
          }
        },
        // {
        //   label: 'common.age',
        //   field: 'age',
        //   type: 'number',
        //   width: '100px',
        // },
        // {
        //   label: 'member.sex',
        //   field: 'isMale',
        //   width: '100px',
        //   changeHTML: function(value, row) {
        //     if (value === true) return common.getI18n('member.sex_male')
        //     if (value === false) return common.getI18n('member.sex_female')
        //     return ''
        //   }
        // },
        {
          label: 'common.floor',
          field: 'floorName',
          width: '110px',
        },
        {
          label: 'common.room',
          field: 'roomName',
          width: '110px',
        },
        {
          label: 'common.bed',
          field: 'bedNumber',
          width: '140px',
        },
        {
          label: 'member.status',
          field: 'status',
          change: this.changeStatus,
          width: '100px',
        },
      ]
    },
  },

  async created() {
    if (!common.isAdmin()) {
      common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
      common.getSearchMemberOptions('memberCode', common.getRole().companyID, common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
      common.getSearchMemberOptions('memberCode', common.getAdminCompanyId(), common.getAdminFacilityId(), this)
    }
    common.getSearchRoomOptions('roomID', this.search_default.floorID, this)

    common.getSearchMemberStatusOptions('statusList', this)
      .then(res => this.status_map = res)

    this.$requestWehealth({
      method: 'get',
      url: '/kiosk/GetMeasureDropDownList',
    }).then(({ data }) => {
      this.bg_period_map = data?.bgPeriod ?? []
      this.dietary_map = data?.dietary ?? []
      this.conscious_map = data?.conscious ?? []
      this.emotional_map = data?.emotional ?? []
    })

    // get TaskTypes and set a default value
    const taskTypes = await this.getSearchTaskTypeOptions('taskType', this)
    if (taskTypes && taskTypes.length > 0 && taskTypes[0].value) {
      this.$refs.search.list.taskType = taskTypes[0].value
      this.getList()
    }
  },

  methods: {
    // Search
    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
        memberCode: vmOfSearch.list.memberCode,
        staffCode: vmOfSearch.list.staffCode,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },
    getSearchTaskTypeOptions(field_name, obj) {
      return this.getTaskTypeOptions()
        .then(res => {
          common.buildSearchOptions(field_name, obj, res)
          return res
        })
    },
    getTaskTypeOptions() {
      return common.apiGetData('/kioskmanage/GetTaskType')
        .then(res => {
          return common.getSelectOptions(res.data, 'statusDesc', 'statusCode')
        })
    },

    onDateRangeChange(selectedDates, dateStr, instance) {
      if (selectedDates.length === 2 || selectedDates.length === 0) {
        this.runSearch()
      }
    },

    // Table
    getMeasureDetail(value, row) {
      let keyOfTime = ''
      let result = ''
      switch (this.taskType) {
        case 'bp':
          keyOfTime = "bP_Measured_DateTime"
          result = row.bP_Systolic ? `${row.bP_Systolic}/${row.bP_Diastolic}mmHg` : ''
          result += row.bP_Pulse ? `, ${row.bP_Pulse}bpm` : ''
          break
        case "bo":
          keyOfTime = "spO2_Measured_DateTime"
          result = row.spO2 ? `${row.spO2}%` : ''
          break
        case "glucose":
          keyOfTime = "bG_Measured_DateTime"
          result = row.bG_Glucose
            ? `${this.bg_period_map?.find(e => e.statusCode === row.bG_Period)?.statusDesc ?? row.bG_Period} ${row.bG_Glucose}mmol/L`
            : ''
          break
        case "bt":
          keyOfTime = "bT_Measured_DateTime"
          result = row.bt ? `${row.bt}℃` : ''
          break
        case "breath":
          keyOfTime = "breaths_Measured_DateTime"
          result = row.breaths ? `${row.breaths}bpm` : ''
          break
        case "conscious":
          keyOfTime = "conscious_Measured_DateTime"
          result = row.conscious
            ? this.conscious_map?.find(e => e.statusCode === row.conscious)?.statusDesc ?? row.conscious
            : ''
          break
        case "dietary":
          keyOfTime = "dietary_Measured_DateTime"
          result = row.dietary
              ? this.dietary_map?.find(e => e.statusCode === row.dietary)?.statusDesc ?? row.dietary
              : ''
          break
        case "emotional":
          keyOfTime = "emotional_Measured_DateTime"
          result = row.emotional
              ? this.emotional_map?.find(e => e.statusCode === row.emotional)?.statusDesc ?? row.emotional
              : ''
          break
        case "handgrip":
          keyOfTime = "handgrip_Measured_DateTime"
          result = row.handgrip ? `${row.handgrip}mbar` : ''
          break
        case "height":
          keyOfTime = "height_Measured_DateTime"
          result = row.weight ? `${row.weight}cm` : ''
          break
        case "weight":
          keyOfTime = "weight_Measured_DateTime"
          result = row.weight ? `${row.weight}kg` : ''
          break
      }
      return row[keyOfTime]
        ? `<span>${this.changeTimeFormat(row[keyOfTime])}</span><br /><span class="measureDetail">${result}</span>`
        : ''
    },
    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = {
        orderBy: value.orderBy === '_measureTime' ? this.taskType : value.orderBy,
        isDesc: value.isDesc,
      }
      this.getList()
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    handleFetchedList: function (rows) {
      return rows.map(row => {
        // row = _.mapKeys(row, (value, key) => key.toLowerCase()) // 转换所有 key 为全小写

        return {
          ...row,
          "_measureTime": row[this.keyOfCurrentTaskTypeMeasureTime],
        }
      })
    },
    changeStatus: function (value) {
      for (const i in this.status_map) {
        if (this.status_map[i].value === value) {
          return this.status_map[i].text
        }
      }
    },
    getList: async function () {
      if (!this.view_permission) return

      const vmOfSearch = this.$refs.search
      const params = Object.assign({
        isCare: false,
        startDate: this.date_range[0],
        endDate: this.date_range[1],
        orderBy: vmOfSearch.list.taskType,
        isDesc: true
      }, this.getSearchCondition(), this.sort_condition, this.page_condition)
      params.statusList = params.statusList ? params.statusList.join(',') : null
      const { data } = await this.$requestWehealth({
        method: 'get',
        url: '/kioskmanage/GetWhetherToCareList',
        params
      })

      // 更新測量項目
      this.taskType = this.$refs.search.list.taskType
      this.taskTypeDesc = this.search_fields.find(f => f.field === 'taskType').options
        .find(o => o.value === this.taskType).text

      // Assign value to current component
      this.total_rows = data.total
      this.rows = this.handleFetchedList(data.members)
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    changeTimeFormat(time) {
      if (!time) {
        return ''
      } else {
        return this.$moment(time)
          .format('YYYY-MM-DD HH:mm:ss')
      }
    },
  }
}
</script>


<style lang="scss" scoped>
$elmHeight: 38px;
.tab-header {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;

  .form-group {
    margin: 0;
  }

  ::v-deep .btn {
    height: $elmHeight;
  }

  .date-range-wrapper {
    display: flex;
    width: 34rem;

    .form-control {
      width: 15rem;
      height: $elmHeight;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }

    .btn-group {
      height: $elmHeight;

      > .btn:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

::v-deep .measureDetail {
  font-size: smaller;
  opacity: 0.7;
}
</style>
